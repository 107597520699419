<script>
    import {address} from "../../../store/main";
    import {getGeoLocationByAddress} from "../../../store/getGeoLocationByAddress";
    import {onMount} from "svelte";
    import {getStaticPlacementTypes} from "../../../store/filters/getStaticPlacementTypes";

    let locationValueInputText = "";
    let placementTypeValueInputText = "";
    let currentSelectedPlacementType = ""
    let currentAvailablePlacementTypes = []
    let selectedPlacementTypeId;
    let placementTypeInputField;
    let showPlacementList = true
    let urlEMSXSection;

    onMount(() => {
        urlEMSXSection = JSON.parse(
            document.getElementById("emsx-location-page-data").textContent,
        );
    });

    const handleSubmit = () => {
        const getGeo = $getGeoLocationByAddress
        console.log("SUBMIT getGeo::", getGeo)
        const currentLatLng = getGeo.geometry;
        const currentCitySelected = getGeo.formattedAddress
        localStorage.setItem("currentCitySelected", currentCitySelected)
        console.log("SUBMIT selectedPlacementTypeId", selectedPlacementTypeId)
        console.log("SUBMIT currentLatLng", currentLatLng)
        window.location = generateNewSerachUrl(currentLatLng, selectedPlacementTypeId);
    };

    const generateNewSerachUrl = (currentLatLng, placementTypeId) => {
        const lat = currentLatLng.lat;
        const lng = currentLatLng.lng;
        const urlBase = `/search?q=${locationValueInputText}&latitude=${lat}&longitude=${lng}`;
        if (selectedPlacementTypeId) return `${urlBase}&placement_types=${placementTypeId}`;
        return urlBase;
    }

    const generateUrl = (currentLatLng, placementTypeId) => {
        const regexTarget = /\./gi;
        const lat = currentLatLng.lat.toString().replace(regexTarget, "x");
        const lng = currentLatLng.lng.toString().replace(regexTarget, "x");
        const urlMapSectionWithoutPT = `map/${lat}/${lng}/x/x/x`;
        const urlMapSectionWithPT = `map/${lat}/${lng}/${placementTypeId}/x/x`;
        if (selectedPlacementTypeId) return urlEMSXSection + urlMapSectionWithPT
        return urlEMSXSection + urlMapSectionWithoutPT;
    };

    const handleLocationInputChange = e => {
        console.log("Input into location bar:", e.target.value)
        $address = e.target.value;
    };

    const handlePlacementTypeInputChange = e => {
        showPlacementList = true;
        const userInput = e.target.value.toLowerCase()
        const availablePlacementTypes = []
        let availablePlaces = []
        $getStaticPlacementTypes.forEach(type => {
            const obj = {
                type: type.type.toLowerCase(),
                id: type.id
            }
            availablePlacementTypes.push(obj)
        })

        availablePlacementTypes.forEach(placement => {
            if (placement.type.indexOf(userInput) > -1) {
                const obj = {
                    type: placement.type.charAt(0).toUpperCase() + placement.type.slice(1),
                    id: placement.id
                }
                availablePlaces.push(obj)
            }
        })
        currentAvailablePlacementTypes = availablePlaces

    }
    const selectPlacementType = (type, id) => {
        selectedPlacementTypeId = id
        showPlacementList = false
        currentSelectedPlacementType = type
    }

    const handlePlacementInputFieldClick = () => {
        currentSelectedPlacementType = ""
        placementTypeInputField.focus()
    }

</script>

<div class="w-300px xs:w-2/3 md:w-17/20 lg:w-780px mx-auto">
    <div class="bg-white rounded-lg mediumRoundedFull p-10px customBoxShadow">
        <form
                on:submit|preventDefault={handleSubmit}
                class="w-full flex md:items-center justify-between flex-row items-center gap-4"
        >
            <section class="flex w-full flex-col md:flex-row gap-1 md:gap-4">
                <div
                        class=" inline-block py-10px lg:px-15px md:py-0 md:pl-0 lg:mr-30px show-placeholder md:min-w-190px lg:min-w-225px"
                >
                    <div class="md:pl-2">
                        <div class="relative h-24px w-full">
                            <input
                                    class="show-placeholder w-180px bg-white focus:bg-white outline-none focus:online-none focus:border-none absolute h-24px"
                                    on:input={e => handleLocationInputChange(e)}
                                    bind:value={locationValueInputText}
                                    on:click={ () => locationValueInputText = ''}
                                    type="text"
                                    id="EMSXSearchFormSvelteMap"
                                    placeholder="Location"
                            />
                            {#if locationValueInputText}
                                <label
                                        class=" focus:online-none focus:border-none focus:bg-white absolute truncate bg-white"
                                        for="EMSXSearchFormSvelteMap"
                                >
                                    {$getGeoLocationByAddress.formattedAddress || locationValueInputText}
                                </label>
                            {/if}
                        </div>
                    </div>
                </div>
                <div class="mt-10px md:mt-0 border-b md:border-l border-light-grey"></div>
                <div
                        class=" inline-block py-10px md:pr-10px md:py-0 md:pl-0 md:mr-10px lg:mr-30px show-placeholder w-full"
                >
                    <div class="">
                        <div class="relative h-24px w-full top-2px">
                            <input
                                    class="show-placeholder w-180px md:w-245px outline-none focus:online-none focus:border-none absolute"
                                    on:input={e => handlePlacementTypeInputChange(e)}
                                    bind:this={placementTypeInputField}
                                    bind:value={placementTypeValueInputText}
                                    on:click={() => currentSelectedPlacementType = ''}
                                    type="text"
                                    placeholder="Placement Type"
                            />
                            {#if placementTypeValueInputText}
                                <label
                                        on:click={handlePlacementInputFieldClick}
                                        class=" focus:online-none focus:border-none absolute truncate bg-white"
                                        for="thisIdDoesNotMatterButMustBeHere"
                                >
                                    {currentSelectedPlacementType}
                                </label>
                            {/if}
                            {#if (currentAvailablePlacementTypes.length > 0) && showPlacementList}
                                <i class="fal fa-chevron-down absolute right-0 top-8px text-dark-grey"></i>
                                <div class="bg-white absolute top-10px mt-4 w-full min-w-180px">
                                    <ul class="border-light-grey-1px">
                                        {#each currentAvailablePlacementTypes as types}
                                            <li on:click={() => selectPlacementType(types.type, types.id)}
                                                class="text-16px cursor-pointer hover:bg-light-grey p-2">{types.type}
                                            </li>
                                        {/each}
                                    </ul>
                                </div>
                            {/if}
                        </div>
                    </div>
                </div>
            </section>
            <div class="h-100px md:h-full flex items-center border-l border-light-grey">
                <button
                        class="form-title rounded-full py-1 px-25px py-10px mx-3 bg-light-blue font-medium"
                        type="submit"
                ><p class="hidden md:block">Search</p>
                    <div class="block md:hidden">
                        <i class="fas fa-search"/>
                    </div>
                </button>
            </div>
        </form>
    </div>
</div>

<style>
    @media only screen and (min-width: 768px) {
        .mediumRoundedFull {
            border-radius: 9999px !important;
        }
    }

    .customBoxShadow {
         box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
</style>